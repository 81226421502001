<template>
  <!-- ●TODO 画面デザイン -->
  <v-app>
    <v-img
      :src="require('@/assets/logo.png')"
      class="mx-auto"
      max-height="220"
      max-width="220"
    />
    <v-container>
      <v-row>
        <v-col>
          <v-card width="400px" class="mx-auto">
            <v-form ref="form" @submit.prevent="login">
              <v-card-title>ログイン</v-card-title>
              <v-card-text>
                <v-alert
                  dismissible
                  type="warning"
                  v-if="warningMessage"
                >
                {{warningMessage}}
                </v-alert>
                <v-alert
                  dismissible
                  type="error"
                  v-if="errorMessage"
                >
                {{errorMessage}}
                </v-alert>
                  <v-row>
                    <!-- ログインID -->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="loginId"
                        label="ログインID"
                        append-icon="mdi-account-circle"
                        hint="メールアドレスの@以前がログインIDです"
                      >
                      </v-text-field>
                    </v-col>
                    <!-- パスワード -->
                    <v-col cols="12" sm="12" v-if="!newPasswordRequired">
                      <v-text-field
                        v-model="password"
                        label="パスワード"
                        :append-icon="isPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (isPassVisible = !isPassVisible)"
                        :type="isPassVisible ? 'text' : 'password'"
                      >
                      </v-text-field>
                    </v-col>
                    <!-- 新パスワード -->
                    <v-col cols="12" sm="12" v-if="newPasswordRequired">
                      <v-text-field
                        v-model="newPassword"
                        label="新パスワード"
                        :append-icon="isNewPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (isNewPassVisible = !isNewPassVisible)"
                        :type="isNewPassVisible ? 'text' : 'password'"
                      >
                      </v-text-field>
                    </v-col>
                    <!-- パスワード（確認） -->
                    <v-col cols="12" sm="12" v-if="newPasswordRequired">
                      <v-text-field
                        v-model="confirmPassword"
                        label="パスワード（確認）"
                        :append-icon="isNewPassConfirmVisible ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (isNewPassConfirmVisible = !isNewPassConfirmVisible)"
                        :type="isNewPassConfirmVisible ? 'text' : 'password'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5"></v-divider>
              </v-card-text>
              <v-card-actions class="justify-center">
                <!-- ログイン -->
                <v-btn
                  color="secondary"
                  type="submit"
                  rounded
                  class="my-2"
                >
                  <v-icon left> mdi-login  </v-icon>ログイン
                </v-btn>
              </v-card-actions>
              <v-card-actions class="justify-center">
                <v-row justify="center">
                  <v-dialog
                    v-model="dialog"
                    persistent
                    width="400px"
                    max-width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <a v-bind="attrs" v-on="on" style="font-size: 12px;"><u>パスワードを忘れた方はこちら</u></a>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">
                        パスワードのリセット
                      </v-card-title>
                      <v-card-text>
                        <v-alert
                          dismissible
                          type="warning"
                          v-if="resetWarningMessage"
                          v-html="resetWarningMessage"
                        >
                        </v-alert>
                        <v-alert
                          dismissible
                          type="error"
                          v-if="resetErrorMessage"
                          v-html="resetErrorMessage"
                        >
                        </v-alert>
                        <v-alert
                          dismissible
                          type="success"
                          v-if="resetComplete"
                        >
                        パスワードの更新が完了しました
                        </v-alert>
                        <v-form ref="form">
                          <v-row>
                            <!-- ログインID -->
                            <v-col cols="12" sm="12">
                              <v-text-field
                                v-model="resetLoginId"
                                label="ログインID"
                                append-icon="mdi-account-circle"
                                :readonly="resetPasswordRequired"
                                v-if="!resetComplete"
                              >
                              </v-text-field>
                            </v-col>
                            <!-- リセットコード -->
                            <v-col cols="12" sm="12" v-if="resetPasswordRequired">
                              <v-text-field
                                v-model="resetCode"
                                label="リセットコード"
                                :append-icon="isResetPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="() => (isResetPassVisible = !isResetPassVisible)"
                                :type="isResetPassVisible ? 'text' : 'password'"
                              >
                              </v-text-field>
                            </v-col>
                            <!-- 新パスワード -->
                            <v-col cols="12" sm="12" v-if="resetPasswordRequired">
                              <v-text-field
                                v-model="resetNewPassword"
                                label="新パスワード"
                                :append-icon="isNewResetPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="() => (isNewResetPassVisible = !isNewResetPassVisible)"
                                :type="isNewResetPassVisible ? 'text' : 'password'"
                              >
                              </v-text-field>
                            </v-col>
                            <!-- パスワード（確認） -->
                            <v-col cols="12" sm="12" v-if="resetPasswordRequired">
                              <v-text-field
                                v-model="confirmResetPassword"
                                label="パスワード（確認）"
                                :append-icon="isNewPassConfirmVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="() => (isNewPassConfirmVisible = !isNewPassConfirmVisible)"
                                :type="isNewPassConfirmVisible ? 'text' : 'password'"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-divider class="mt-5"></v-divider>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <!-- リセットコード送信 -->
                        <v-btn
                          color="secondary"
                          v-if="!resetPasswordRequired && !resetComplete"
                          v-on:click="sendResetCode"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-login  </v-icon>送信
                        </v-btn>
                        <!-- パスワードリセット実行 -->
                        <v-btn
                          color="secondary"
                          v-if="resetPasswordRequired"
                          v-on:click="confirmPasswordReset"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-login  </v-icon>変更
                        </v-btn>
                      </v-card-actions>
                      <v-card-actions class="justify-center">
                        <!-- ログイン -->
                        <v-btn
                          color="secondary"
                          @click="closePasswordReset"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-login  </v-icon>閉じる
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { Auth } from 'aws-amplify'
import { addLog } from '@/assets/js/logger';
import { setUserData } from '@/assets/js/common';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "Login",

  data: () => ({
    // ログインID
    loginId: '',
    // パスワードリセット用ログインID
    resetLogintId: '',
    // メールアドレス（検証用）
    mail: '',
    mailAddress: '',
    // パスワード
    password: '',
    isPassVisible: false,
    isNewPassVisible: false,
    isNewPassConfirmVisible: false,
    // パスワード変更
    newPasswordRequired: false,
    newPassword: '',
    confirmPassword: '',
    // パスワード初期化
    resetLoginId: '',
    resetPassword: '',
    resetNewPassword: '',
    confirmResetPassword: '',
    dialog: false,
    isResetPassVisible: false,
    isNewResetPassVisible: false,
    resetPasswordRequired: false,
    resetComplete: false,

    // メッセージ
    errorMessage: '',
    warningMessage: '',
    // パスワードリセットメッセージ
    resetErrorMessage: '',
    resetWarningMessage: '',
  }),
  computed: {},
  mounted() {
    // メソッド名を定義
    const method_name="mounted"
    // オペレーションタイプを定義
    const operation_type="0007"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // ローディングエフェクトOFF
    this.$store.commit("setLoading", false);

    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    // ログインボタン
    async login() {
      // メソッド名を定義
      const method_name="login"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // console.log('login called');
      // ローディングエフェクトON
      this.$store.commit("setLoading", true);

      // エラーメッセージリセット
      this.errorMessage = '';
      this.warningMessage = '';
      const result = await this.signIn();
      if(!result) this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    async signIn() {
      // メソッド名を定義
      const method_name="signIn"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (!this.loginId || !this.password) {
          this.warningMessage = 'メールアドレスとパスワードを入力してください';
      } else {
        // console.log(`loginid = ${this.loginid}, password = ${this.password}`);
        // ログイン処理
        try {
          let user = await Auth.signIn(this.loginId, this.password);
          // console.info({user});
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (this.newPasswordRequired) {
              if (this.newPassword && this.confirmPassword) {
                if (this.newPassword !== this.confirmPassword) {
                  this.warningMessage = '同じパスワードを入力してください';
                } else if (this.password === this.newPassword) {
                  this.warningMessage = '新しいパスワードは変更前のパスワードと異なるパスワードである必要があります';
                } else {
                  try {
                    // console.log('completeNewPassword');
                    let newAuth = await Auth.completeNewPassword(user, this.newPassword);
                    // await Auth.completeNewPassword(user, this.newPassword);
                    // // console.log(`user.username = ${newAuth.username}`);
                    let userJudge = await setUserData(newAuth.username);
                    // console.log(userJudge);
                    if (userJudge) {
                      this.$router.push('/')
                      // ログ出力(method-end)
                      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                      return true;
                    } else {
                      console.log('invalid userJudge on completeNewPassword');
                      this.errorMessage = '認証エラーが発生しました。';
                      // ログ出力(method-end)
                      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                      return false;
                    }
                  } catch (error) {
                    console.log('error completeNewPassword', error);
                    if (error.code) {
                      switch(error.code) {
                        case 'InvalidPasswordException':
                          this.errorMessage = 'パスワードは数字、アルファベットの大文字・小文字、記号を含んだ8文字以上の文字列を設定してください。';
                          break;
                        default:
                          this.errorMessage = '認証エラーが発生しました。';
                          break;
                      }
                    }
                    // ログ出力(method-end)
                    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                    return false;
                  }
                }
              } else {
                this.warningMessage = '新しいパスワードを入力してください';
                // ログ出力(method-end)
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return false;
              }
            } else {
              this.warningMessage = '初期パスワードを変更する必要があります。\n新しいパスワードを入力してください。';
              this.newPasswordRequired = true;
              this.confirmPassword = '';
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              return false;
            }
          } else {
            // console.log(user);
            // console.log(`user.username = ${user.username}`);
            let userJudge = await setUserData(user.username);
            if (userJudge) {
                this.$router.push('/')
                // ログ出力(method-end)
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return true;
            } else {
                // TODO 「認証は通りましたが担当者マスタにデータがありません」
                this.errorMessage = '認証エラーが発生しました。';
                // ログ出力(method-end)
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return false;
            }
          }
        } catch (error) {
          // console.log('error signing in', error);
          if (error.code) {
            console.log(error.code);
            switch(error.code) {
              case 'UserNotFoundException':
              case 'NotAuthorizedException':
              case 'InvalidParameterException':
                this.errorMessage = 'ユーザーIDかパスワードが正しくありません';
                break;
              default: {
                // 異常系操作ログの登録
                // let param = {};
                // addOperationLogs('Error', 'ivr-login', 'signIn', param, error);
                this.errorMessage = '認証エラーが発生しました。';
                break;
              }
            }
          }
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return false;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    // パスワードリセットメール送信処理
    async sendResetCode() {
      try {
        // メッセージ初期化
        this.resetWarningMessage = '';
        this.resetErrorMessage = '';

        // リセットコード送信
        await Auth.forgotPassword(this.resetLoginId);

        // メッセージ表示
        this.resetWarningMessage = 'リセットコードを送信しました<br/>リセットコードと新しいパスワードを入力してください';
        this.resetPasswordRequired = true;
      } catch (error) {
        console.log({error});
        // 異常系操作ログの登録
        addLog('ERROR', {}, this.$route.name, 'sendResetCode', TYPE["0003"], error);

        if (error.code) {
          switch(error.code) {
            case 'UserNotFoundException':
            case 'NotAuthorizedException':
              this.resetErrorMessage = '存在しないユーザーIDです';
              break;
            case 'LimitExceededException':
              this.resetErrorMessage = '所定の回数を超えるリクエストが送信されました。<br/>しばらく時間をおいてから再度お試しください。';
              break;
            default:
              break;
          }
        }
      }
    },
    // パスワードリセット処理
    async confirmPasswordReset() {
      // メッセージ初期化
      this.resetWarningMessage = '';
      this.resetErrorMessage = '';

      // バリデーション
      if(!await this.validateResetParams()) return;

      try {
        // パスワードリセットサブミット
        await Auth.forgotPasswordSubmit(this.resetLoginId, this.resetCode, this.confirmResetPassword)

        // パスワード変更ステータス初期化
        this.resetPasswordRequired = false;
        // パスワード変更完了ステータスON
        this.resetComplete = true;
      } catch (error) {
        console.log({error});
        // 異常系操作ログの登録
        addLog('ERROR', {}, this.$route.name, 'confirmPasswordReset', TYPE["0003"], error);

        if (error.code) {
          switch(error.code) {
            case 'UserNotFoundException':
            case 'NotAuthorizedException':
              this.resetErrorMessage = '存在しないユーザーIDです';
              break;
            case 'InvalidPasswordException':
              this.resetErrorMessage = 'パスワードは数字、アルファベットの大文字・小文字、記号を含んだ<br/>8文字以上の文字列を設定してください。';
              break;
            default:
              break;
          }
        }
      }
    },
    // パスワードリセットパラーメータバリデーション
    validateResetParams() {
      // パスワードチェック
      if(this.resetNewPassword !== this.confirmResetPassword) {
        this.resetErrorMessage = '「新パスワード」と「パスワード（確認）」の値が異なります';
        return false;
      }
      return true;
    },
    // パスワードリセットモーダルクローズ処理
    closePasswordReset() {
      this.dialog = false;
      this.resetLoginId = '';
      this.resetCode = '';
      this.resetPassword = '';
      this.confirmResetPassword = '';
      this.resetPasswordRequired = false;
      this.resetComplete = false;
      this.resetWarningMessage = '';
      this.resetErrorMessage = '';
    }
  },
};
</script>